var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.category.length)?_c('div',{staticClass:"body"},[_c('div',{staticStyle:{"overflow-y":"auto","width":"40%"}},_vm._l((_vm.category),function(item,index){return _c('div',{key:index,class:{
        'mt-4': index > 0,
        'box-category': true,
        'cursor-pointer': true,
      },on:{"click":function($event){return _vm.goToPage(item.id)}}},[_c('h1',{staticClass:"box-category-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticStyle:{"display":"flex","width":"250px"}},[_c('div',{staticStyle:{"margin-right":"auto"}},[_c('p',{staticClass:"box-category-text",staticStyle:{"margin-right":"auto"}},[_vm._v(" "+_vm._s(item.events_name.join(", "))+" ")]),_c('div',{staticClass:"box-category-count"},[_c('div',[_c('h3',{staticClass:"box-category-count-title"},[_vm._v(" "+_vm._s(item.total_events)+" ")]),_vm._m(0,true)]),_c('div',[_c('h3',{staticClass:"box-category-count-title"},[_vm._v(" "+_vm._s(item.total_registered)+" ")]),_vm._m(1,true)])])]),_c('div',[_c('Arrow',{attrs:{"direct":true}})],1)])])}),0),_c('div',{staticClass:"box-chart"},[_c('h2',{staticClass:"text-title-chart",staticStyle:{"color":"#36373f"}},[_vm._v("Revenue (IDR)")]),_c('div',{staticStyle:{"display":"flex","margin-top":"33px"}},[_c('div',[_c('h5',{staticClass:"total-income-heading-5"},[_vm._v("Total "+_vm._s(new Date().getFullYear())+" Income")]),_c('div',{staticClass:"total-income"},[_c('div',{staticClass:"total-income",staticStyle:{"margin-right":"10px"}}),_c('div',{staticClass:"total-income-persentase"},[_c('Arrow2',{attrs:{"fill":'#0ea976'}})],1)])]),_c('div',{staticStyle:{"margin-left":"auto"}},[_c('div',{staticClass:"total-income-btn-group"},[_c('button',{class:[
              ' total-income-btn-left ',
              _vm.isDate ? ' total-income-btn-1 ' : ' total-income-btn-2 ',
            ],on:{"click":function($event){return _vm.getMounth(true)}}},[_vm._v(" Month ")]),_c('button',{class:[
              ' total-income-btn-right ',
              _vm.isDate ? ' total-income-btn-2 ' : ' total-income-btn-1 ',
            ],on:{"click":function($event){return _vm.getMounth(false)}}},[_vm._v(" Year ")])]),_vm._l((_vm.category),function(data2,idx2){return _c('div',{key:idx2,staticClass:"total-income-legend"},[_c('div',{staticClass:"modal-total-income-legend"},[_c('h5',{staticClass:"text-total-income-legend"},[_vm._v(_vm._s(data2.name))]),_c('div',{staticClass:"color-total-income-legend-1"})])])})],2)]),_c('div',{staticClass:"chart-container"},[_c('apexchart',{attrs:{"type":"bar","options":_vm.chartOptions,"series":_vm.series}})],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"box-category-count-text"},[_vm._v(" Total "),_c('br'),_vm._v(" Program ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"box-category-count-text"},[_vm._v(" Peserta "),_c('br'),_vm._v(" Terdaftar ")])
}]

export { render, staticRenderFns }