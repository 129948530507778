<template>
  <div v-if="category.length" class="body">
    <div style="overflow-y: auto; width: 40%;">
      <div
        v-for="(item, index) in category"
        :key="index"
        :class="{
          'mt-4': index > 0,
          'box-category': true,
          'cursor-pointer': true,
        }"
        @click="goToPage(item.id)"
      >
        <h1 class="box-category-title">
          {{ item.name }}
        </h1>
        <div style="display: flex; width: 250px">
          <div style="margin-right: auto">
            <p style="margin-right: auto" class="box-category-text">
              {{ item.events_name.join(", ") }}
            </p>
            <div class="box-category-count">
              <div>
                <h3 class="box-category-count-title">
                  {{ item.total_events }}
                </h3>
                <h5 class="box-category-count-text">
                  Total <br />
                  Program
                </h5>
              </div>
              <div>
                <h3 class="box-category-count-title">
                  {{ item.total_registered }}
                </h3>
                <h5 class="box-category-count-text">
                  Peserta <br />
                  Terdaftar
                </h5>
              </div>
            </div>
          </div>
          <div><Arrow :direct="true" /></div>
        </div>
      </div>
    </div>
    <div class="box-chart">
      <h2 style="color: #36373f" class="text-title-chart">Revenue (IDR)</h2>
      <div style="display: flex; margin-top: 33px">
        <div>
          <h5 class="total-income-heading-5">Total {{ new Date().getFullYear() }} Income</h5>
          <div class="total-income">
            <div style="margin-right: 10px" class="total-income">
            </div>
            <div class="total-income-persentase">
              <Arrow2 :fill="'#0ea976'" />
            </div>
          </div>
        </div>
        <div style="margin-left: auto">
          <div class="total-income-btn-group">
            <button
              @click="getMounth(true)"
              :class="[
                ' total-income-btn-left ',
                isDate ? ' total-income-btn-1 ' : ' total-income-btn-2 ',
              ]"
            >
              Month
            </button>
            <button
              @click="getMounth(false)"
              :class="[
                ' total-income-btn-right ',
                isDate ? ' total-income-btn-2 ' : ' total-income-btn-1 ',
              ]"
            >
              Year
            </button>
          </div>
          <div class="total-income-legend" v-for="(data2, idx2) in category" :key="idx2">
            <div class="modal-total-income-legend">
              <h5 class="text-total-income-legend">{{ data2.name }}</h5>
              <div class="color-total-income-legend-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-container">
        <apexchart
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </div>
</template>
<script>
import Arrow from "../../../../components/icons/Arrow.vue";
import Arrow2 from "../../../../components/icons/Arrow2.vue";
import VueApexCharts from "vue-apexcharts";
export default {
  data() {
    return {
      category: [],
      summary: {
        2024: {},
        2025: {}
      },
      series: [
        {
          name: "Webinar",
          data: [10, 30],
        },
        {
          name: "Mini Bootcamp",
          data: [20, 10],
        },
        {
          name: "Mini Class",
          data: [20, 10],
        },
      ],
      chartOptions: {},
      isDate: false,
    };
  },

  components: {
    Arrow,
    Arrow2,
    apexchart: VueApexCharts,
  },
  methods: {
    async fetchTransactionEvent() {
      try {
        const response = await this.$crm_http.get(`/v1/transaction/event/category`)

        this.category = response.data.data.event_categories;
        this.summary = response.data.data.summary
        this.chartOptions = {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          colors: ["#4EA8DE", "#48BFE3", "#5AB7E5"],
          plotOptions: {
            bar: {
              borderRadius: 5,
              columnWidth: "50px",
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            custom: ({ dataPointIndex, w }) => {
              const total =
                w.globals.series[0][dataPointIndex] +
                w.globals.series[1][dataPointIndex];
              const seriesData = w.globals.series
                .map((series, index) => {
                  return `
          <div style="margin-bottom: 12px;">
            <div style="display: flex; align-items: center;">
              <div style="width: 12px; height: 12px; border-radius: 50%; background-color: ${w.globals.colors[index]}; margin-right: 8px;"></div>
              <span style="font-weight: 400; color: #7B7E8C; margin-right: auto; font-size: 12px;">${w.globals.seriesNames[index]}</span>
              <span style="font-weight: 500; color: #575966; font-size: 14px;">${series[dataPointIndex]}</span>
            </div>
          </div>
        `;
                })
                .join("");

              return `
        <div style="background: white; border-radius: 8px; box-shadow: 0 4px 12px rgba(0,0,0,0.1); min-width: 200px;">
          <div style="margin-bottom: 12px; padding: 8px 16px; background-color: #E7E8F0;">
            <span style="font-weight: 400; color: #7B7E8C; margin-right: auto; font-size: 14px;">Total</span>
            <span style="font-weight: 500; color: #575966; font-size: 14px;">${total}</span>
          </div>
          <div style="padding: 0px 16px;">
            ${seriesData}
          </div>
        </div>
      `;
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          xaxis: {
            categories: ["2020", "2021", "2022", "2023", "2024"],
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
          },
          grid: {
            show: true,
            strokeDashArray: 5,
          },
          fill: {
            opacity: 1,
          },
          stroke: {
            width: 0,
          },
        };

        const yearsArray1 = Object.keys(response.data.data.summary).map(
          (year) => response.data.data.summary[year].event_categories[0].total_revenue
        );
        const yearsArray2 = Object.keys(response.data.data.summary).map(
          (year) => response.data.data.summary[year].event_categories[1].total_revenue
        );
        const yearsArray3 = Object.keys(response.data.data.summary).map(
          (year) => response.data.data.summary[year].event_categories[2].total_revenue
        );

        this.series = [
          {
            name: "Webinar",
            data: [0, 0, 0, ...yearsArray1],
          },
          {
            name: "Mini Bootcamp",
            data: [0, 0, 0, ...yearsArray2],
          },
          {
            name: "Mini Class",
            data: [0, 0, 0, ...yearsArray3],
          },
        ];
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },

    goToPage(id) {
      this.$router.push(`/transaction/b2b/event/${id}`);
    },

    async getMounth(staust) {
      try {
        if (staust) {
          const response = await this.$crm_http.get(`/v1/transaction/event/category?filter=month`)
          const datas = response.data?.data?.summary;
          
          for (const key in datas) {
            if (datas[key] === null) {
              datas[key] = [0, 0, 0];
            } else if (datas[key].event_categories) {
              const revenues = await Promise.all(
                datas[key].event_categories.map(async (category) => {
                  return category.total_revenue; // Jika ini adalah operasi async
                })
              );
              while (revenues.length < 3) revenues.push(0);
              datas[key] = revenues.slice(0, 3);
            }
          }

          const sortedFirstElements = await Promise.all(
            Object.keys(datas)
              .sort((a, b) => parseInt(a) - parseInt(b))
              .map(async (key) => datas[key][0])
          );

          const sortedSecondElements = await Promise.all(
            Object.keys(datas)
              .sort((a, b) => parseInt(a) - parseInt(b))
              .map(async (key) => datas[key][1])
          );

          this.chartOptions = {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            colors: ["#4EA8DE", "#48BFE3", "#5AB7E5"],
            plotOptions: {
              bar: {
                borderRadius: 5,
                columnWidth: "50px",
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            tooltip: {
              enabled: true,
              shared: true,
              intersect: false,
              custom: ({ dataPointIndex, w }) => {
                const total =
                  w.globals.series[0][dataPointIndex] +
                  w.globals.series[1][dataPointIndex];
                const seriesData = w.globals.series
                  .map((series, index) => {
                    return `
          <div style="margin-bottom: 12px;">
            <div style="display: flex; align-items: center;">
              <div style="width: 12px; height: 12px; border-radius: 50%; background-color: ${w.globals.colors[index]}; margin-right: 8px;"></div>
              <span style="font-weight: 400; color: #7B7E8C; margin-right: auto; font-size: 12px;">${w.globals.seriesNames[index]}</span>
              <span style="font-weight: 500; color: #575966; font-size: 14px;">${series[dataPointIndex]}</span>
            </div>
          </div>
        `;
                  })
                  .join("");

                return `
        <div style="background: white; border-radius: 8px; box-shadow: 0 4px 12px rgba(0,0,0,0.1); min-width: 200px;">
          <div style="margin-bottom: 12px; padding: 8px 16px; background-color: #E7E8F0;">
            <span style="font-weight: 400; color: #7B7E8C; margin-right: auto; font-size: 14px;">Total</span>
            <span style="font-weight: 500; color: #575966; font-size: 14px;">${total}</span>
          </div>
          <div style="padding: 0px 16px;">
            ${seriesData}
          </div>
        </div>
      `;
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            xaxis: {
              categories: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
              ],
              axisBorder: {
                show: true,
              },
              axisTicks: {
                show: true,
              },
            },
            grid: {
              show: true,
              strokeDashArray: 5,
            },
            fill: {
              opacity: 1,
            },
            stroke: {
              width: 0,
            },
          };

          this.series = [
            {
              name: "Webinar",
              data: sortedFirstElements,
            },
            {
              name: "Mini Bootcamp",
              data: sortedSecondElements,
            },
          ];

          this.isDate = true;
        } else {
          this.isDate = false;
          this.fetchTransactionEvent();
        }
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
  },
  mounted() {
    this.fetchTransactionEvent();
  },
};
</script>
<style scoped>
.modal {
  width: 100%;
  display: flex;
}
.chart-container {
  width: 100%;
  height: 100%;
}
.modal-total-income-legend {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}
.total-income-legend {
  margin-top: 18px;
}
.box-chart {
  width: 60%;
  margin: 0px 40px;
}
.text-total-income-legend {
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  color: #7b7e8c;
}
.color-total-income-legend-1 {
  width: 15px;
  height: 15px;
  background-color: #4ea8de;
  border-radius: 9999px;
  margin-left: 10px;
}
.color-total-income-legend-2 {
  width: 15px;
  height: 15px;
  background-color: #48bfe3;
  border-radius: 9999px;
  margin-left: 10px;
}
.total-income-btn-group {
  display: flex;
  padding: auto;
}
.total-income-btn-1 {
  background-color: #2d46cf;
  color: #ffffff;
  width: 68px;
  height: fit-content;
  padding: 6px 14px;
  font-size: 12px;
  font-size: 500;
}

.total-income-btn-2 {
  background-color: #e7e8f0;
  color: #7b7e8c;
  width: 68px;
  height: fit-content;
  padding: 6px 14px;
  font-size: 12px;
  font-size: 500;
}
.total-income-btn-right {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.total-income-btn-left {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.total-income {
  display: flex;
  align-items: center;
}
.total-income-persentase {
  display: flex;
  align-items: center;
  color: #0ea976;
  background-color: #e2fcf3;
  padding: 4px 8px;
  border-radius: 10px;
  column-gap: 8px;
}
.total-income-heading-5 {
  font-size: 12px;
  font-weight: 400 !important;
  color: #36373f;
}
.total-income-heading-1 {
  font-size: 30px !important;
  font-weight: 900 !important;
  line-height: 45px;
  color: #575966;
}
.body {
  background: #ffffff;
  margin: 41px 40px;
  padding: 42px 22px;
  border-radius: 10px;
  display: flex;
  height: fit-content;
}
.box-category2 {
  border: 1px solid #2d46cf;
  width: 300px;
  padding: 15px 25px;
  border-radius: 15px;
}
.box-category {
  border: 1px solid #d3d6e1;
  width: 300px;
  padding: 15px 25px;
  border-radius: 15px;
}
.box-category-count {
  display: flex;
  padding: 15px 25px 15px 25px;
  column-gap: 38px;
  background-color: #f1f6ff;
  border-radius: 5px;
}
.text-title-chart {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
}
.box-category-text {
  color: #7b7e8c;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  width: 215px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.box-category-title {
  color: #36373f;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
}
.box-category-count-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2d46cf;
}
.box-category-count-text {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
}
</style>
